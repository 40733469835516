import React, { useState, useEffect } from "react";
import { useGlobalState, setGlobalState } from "context/globalstate";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiBadge from "components/VuiBadge";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";

// Vision UI Dashboard PRO React examples
import MessageCard from "examples/Cards/MessageCard";

// @mui UI
import {
  styled,
  Grid,
  CircularProgress,
  Icon,
  Stack,
  Link,
  Card,
  Modal,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import MintAddressImgHint from "assets/images/mint-address-hint.png";
import SolgemPlaceHolder from "assets/images/body-background.png";
import defaultMarkers from "../markers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "linear-gradient(127.09deg, rgb(15 18 33 / 94%) 19.41%, rgb(10 14 35 / 11%) 76.65%)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 520,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    background: "url(" + SolgemPlaceHolder + ")",
    backgroundSize: "cover",
    backgroundPosition: "50%",
    color: "white",
    padding: "10px",
  },
}));

import { getSolgemByMintAddress } from "api/Solgem";

function SolgemsCard() {
  const [walletSolgems] = useGlobalState("walletSolgems");
  const [isLoggedIn] = useGlobalState("isLoggedIn");

  const [open, setOpen] = useState(false);
  const [mintAddress, setMintAddress] = useState();
  const [solgemIndex, setSolgemIndex] = useState();
  const [searchOn, setSearchOn] = useState(false);

  const handleSearch = async (e) => {
    e.preventDefault();

    setSearchOn(true);
    getSolgemByMintAddress(mintAddress).then((response) => {
      setSolgemIndex(response);
      setSearchOn(false);
    });
  };

  const toggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    toggle();
    setSolgemIndex(null);
  };

  const handleOpenLands = (parameter) => (event) => {
    if (event.detail == 1) {
      setGlobalState("isLandCardOpened", true);
      setGlobalState("openLandCard", parameter);
    }
  };

  return (
    <>
      <VuiBox
        justifyContent="center"
        alignItems="center"
        width={{ xs: "153rem", lg: "13rem" }}
        height="1rem"
        shadow="sm"
        borderRadius="13%"
        position="fixed"
        left="2.5rem"
        bottom="27rem"
        zIndex={1200}
        display={{ xs: "none", lg: "block" }}
        color="white"
        sx={{ cursor: "pointer" }}
      >
        <Card
          variant="outlined"
          sx={{
            background: "linear-gradient(310deg, #141727, #3a416f)",
          }}
        >
          <VuiBox height="100%" display="flex" flexDirection="column">
            <VuiBox mb="20px">
              <VuiTypography
                color="white"
                variant="h5"
                fontWeight="medium"
                style={{ stroke: "purple" }}
              >
                WALLET GEMS:
              </VuiTypography>

              {walletSolgems.length != 0 ? (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <VuiTypography color="white" variant="h5" fontWeight="regular">
                        Land Numbers:
                      </VuiTypography>
                      <Grid container>{landBadges(walletSolgems)}</Grid>
                    </React.Fragment>
                  }
                  placement="right"
                >
                  <VuiButton
                    type="submit"
                    variant="gradient"
                    color="info"
                    size="small"
                    sx={{ mt: 1 }}
                    fullWidth
                  >
                    SHOW LANDS
                  </VuiButton>
                </HtmlTooltip>
              ) : isLoggedIn ? (
                <Stack mt={2} spacing={2} direction="row">
                  <CircularProgress color="info" size={30} thickness={5} value={100} />
                  <VuiTypography type="submit" color="white" size="small">
                    Loading...
                  </VuiTypography>
                </Stack>
              ) : null}
              <Grid container mt={2}>
                {defaultMarkers.map((rangeLabel) => {
                  return (
                    <Grid item xs={12} lg={12} xl={12} mt={-0.5}>
                      <LightTooltip
                        title={"Click to show '" + rangeLabel.path + "' lands"}
                        placement="right"
                      >
                        <VuiTypography
                          onClick={handleOpenLands(rangeLabel.path)}
                          color="white"
                          variant="button"
                          fontWeight="regular"
                        >
                          {rangeLabel.city}
                        </VuiTypography>
                      </LightTooltip>
                    </Grid>
                  );
                })}
              </Grid>
            </VuiBox>
            <VuiButton variant="gradient" color="info" onClick={toggle}>
              SEARCH SOLGEM
            </VuiButton>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Card sx={style}>
                <Typography id="modal-modal-title" variant="h6" sx={{ color: "white!important" }}>
                  Search by MINT ADDRESS
                  <HtmlTooltip
                    placement="right"
                    title={
                      <React.Fragment>
                        <Typography color="inherit">How to get the mint address? </Typography>
                        <em>{"Get it on "}</em> <b>{"solcan"}</b> <u>{" details section"}</u>.
                        <img src={MintAddressImgHint} loading="lazy" width={500} />
                      </React.Fragment>
                    }
                  >
                    <Icon sx={{ marginLeft: 1, fontSize: "20px!important" }}>helpoutline</Icon>
                  </HtmlTooltip>
                </Typography>
                <VuiBox id="modal-modal-description" sx={{ mt: 2 }}>
                  <form onSubmit={handleSearch}>
                    <VuiInput
                      placeholder="Type here..."
                      icon={{
                        component: "search",
                        direction: "left",
                      }}
                      onChange={(e) => setMintAddress(e.target.value)}
                    />
                    <VuiButton type="submit" variant="gradient" color="info" sx={{ mt: 2 }}>
                      Search
                      {searchOn ? (
                        <CircularProgress sx={{ ml: 2 }} size={20} mar color="white" />
                      ) : null}
                    </VuiButton>
                  </form>
                </VuiBox>

                {solgemIndex ? (
                  <VuiBox>
                    {solgemIndex != null ? (
                      <Typography
                        variant="h6"
                        component="h2"
                        sx={{ color: "white!important", mt: 2 }}
                      >
                        {solgemIndex.solgemIndex != null ? (
                          <MessageCard
                            image={solgemIndex.solgemImageLink}
                            text={
                              "Land Number: " +
                              solgemIndex.solgemIndex +
                              " Continent: " +
                              getLandContinent(solgemIndex.solgemIndex)
                            }
                            action={{
                              type: "internal",
                              route: "/" + solgemIndex.solgemIndex,
                              color: "dark",
                              label: "CHECK LAND",
                            }}
                          />
                        ) : (
                          <>No result found.</>
                        )}
                      </Typography>
                    ) : null}
                  </VuiBox>
                ) : null}
              </Card>
            </Modal>
          </VuiBox>
        </Card>
      </VuiBox>
    </>
  );
}
export default SolgemsCard;

const landBadges = (walletSolgems) => {
  return walletSolgems.map((entry) => {
    return (
      <Grid
        item
        xs={walletSolgems.length > 5 ? 2 : 6}
        lg={walletSolgems.length > 5 ? 2 : 6}
        xl={walletSolgems.length > 5 ? 2 : 6}
      >
        <LightTooltip title={"Click to show land '" + entry + "'"} placement="top">
          <Link key={"land-badge" + entry} href={`/${entry}`}>
            <VuiBadge badgeContent={"No. " + entry} container sx={{ mr: 0.5, mt: 0.5 }} />
          </Link>
        </LightTooltip>
      </Grid>
    );
  });
};

const getLandContinent = (landIndex) => {
  let returnLabel;

  if (landIndex <= 110) {
    returnLabel = "GRASS";
  } else if (landIndex >= 111 && landIndex <= 220) {
    returnLabel = "MOON";
  } else if (landIndex >= 221 && landIndex <= 330) {
    returnLabel = "VOLCANO";
  } else if (landIndex >= 331 && landIndex <= 440) {
    returnLabel = "ROCK";
  } else if (landIndex >= 441 && landIndex <= 550) {
    returnLabel = "SAND";
  } else if (landIndex >= 551 && landIndex <= 660) {
    returnLabel = "BEACH";
  } else if (landIndex >= 661 && landIndex <= 770) {
    returnLabel = "BEACH";
  } else if (landIndex >= 771 && landIndex <= 886) {
    returnLabel = "SNOW";
  }

  return returnLabel;
};
