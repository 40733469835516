import { useState, useEffect } from "react";

import { BrowserRouter as Router, Route, useParams } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { Card } from "@mui/material";
import Link from "@mui/material/Link";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useVisionUIController, setTransparentNavbar, setMiniSidenav } from "context";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Images
import solsphereLogoText from "assets/images/solsphere-virtual-land.png";

// Components
import WalletConnectButton from "examples/Buttons/WalletConnect";

function DashboardNavbar({ absolute, light, isMini }) {
  let { id } = useParams();

  const [landNumber, setLandNumber] = useState(isNumber(id) ? id : null);
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <Link href="/" key="link-to" rel="noreferrer" sx={{ textDecoration: "none" }}>
          <VuiBox color="inherit" sx={(theme) => navbarRow(theme, { isMini })}>
            <Card
              sx={() => ({
                height: "50px",
                width: "520px",
                backgroundImage: `url(${solsphereLogoText})`,
                backgroundSize: "cover",
                borderRadius: 0,
              })}
            ></Card>
            <VuiTypography variant="h2" color={"white"} sx={{ mt: 1, ml: 1 }}>
              {landNumber ? "# " + landNumber : null}
            </VuiTypography>
          </VuiBox>
        </Link>
        {isMini ? null : (
          <VuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            <VuiBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={"text-white"}>{miniSidenav ? "menu_open" : "menu"}</Icon>
              </IconButton>
            </VuiBox>

            <WalletConnectButton />
          </VuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;

function isNumber(n) {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}
