import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  border: "2px solid #ffffff",
  boxShadow: 24,
  p: 3,
  overflow: "inherit",
};

function VuiModal(props) {
  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);

  const handleSubmit = () => {
    props.submitForm();
    toggleModal();
  };

  return (
    <>
      <VuiButton color="info" onClick={toggleModal}>
        ADD
      </VuiButton>
      <Modal
        open={open}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <VuiBox>
            <VuiTypography id="modal-modal-title" variant="h6" component="h2" color="white">
              {props?.title}
            </VuiTypography>
            <VuiTypography id="modal-modal-description" sx={{ mt: 2 }} color="white">
              {props?.content}
              <VuiButton sx={{ mt: 2, mr: 1 }} mt={4} color="info" onClick={handleSubmit}>
                Save
              </VuiButton>
              <VuiButton
                sx={{ mt: 2 }}
                mt={4}
                variant="outlined"
                color="white"
                onClick={(e) => toggleModal()}
              >
                Close
              </VuiButton>
            </VuiTypography>
          </VuiBox>
        </Card>
      </Modal>
    </>
  );
}

export default VuiModal;
