import React, { useState, useEffect } from "react";
import { setGlobalState, useGlobalState } from "context/globalstate";

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

// @material-ui core components
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

import SolLands from "../lands";

const style = {
  position: "absolute",
  zIndex: 1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

function WorldLandCards() {
  const [isLandCardOpened] = useGlobalState("isLandCardOpened");

  const handleClose = () => setGlobalState("isLandCardOpened", false);

  const closeLandCardButton = (
    <VuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3rem"
      height="3rem"
      bgColor="#00cde4"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="1rem"
      top="1rem"
      zIndex={99}
      color="white"
      sx={{ cursor: "pointer" }}
      onClick={handleClose}
    >
      <Icon fontSize="default" color="inherit">
        close
      </Icon>
    </VuiBox>
  );

  return (
    <Modal
      keepMounted
      open={isLandCardOpened}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      width={{ xs: "153rem", lg: "13rem" }}
    >
      <Card style={style}>
        <VuiBox display="flex" flexDirection="column" height="100%">
          <Grid sx={{ justifyContent: "center", mx: "auto" }} container>
            {closeLandCardButton}
            <SolLands />
          </Grid>
        </VuiBox>
      </Card>
    </Modal>
  );
}

export default WorldLandCards;
