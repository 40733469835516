import { createGlobalState } from "react-hooks-global-state";

const LOCAL_STORAGE_SOLGEMS = localStorage.getItem("SOLGEMS");

const isLocalStorageEmpty = LOCAL_STORAGE_SOLGEMS != null && LOCAL_STORAGE_SOLGEMS != "";

const { setGlobalState, useGlobalState } = createGlobalState({
  isWalletConnected: localStorage.getItem("WALLET_ID") != "",
  walletSolgems: isLocalStorageEmpty ? JSON.parse(LOCAL_STORAGE_SOLGEMS) : [],
  openLand: "",

  isLandCardOpened: false,
  isLoggedIn: false,
  openLandCard: "",
});

export { useGlobalState, setGlobalState };
