// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiBadgeDot from "components/VuiBadgeDot";

// Images
import solgem1 from "assets/images/solgems/solgem1.png";
import solgem2 from "assets/images/solgems/solgem2.png";
import solgem3 from "assets/images/solgems/solgem3.png";
import solgem4 from "assets/images/solgems/solgem4.png";
import solgems from "assets/images/solgems/solgem5.png";

export default {
  columns: [
    { name: "rank", align: "center" },
    { name: "name", align: "left" },
    { name: "buildings", align: "left" },
    { name: "visits", align: "left" },
    { name: "started", align: "center" },
  ],

  rows: [
    {
      rank: "#1",
      name: [solgem1, "Land No. 599"],
      buildings: "35",
      hasBorder: true,
      visits: (
        <VuiBox ml={-1.325}>
          <VuiBadgeDot size="xs" badgeContent="x5,502" />
        </VuiBox>
      ),
      started: "04/01/22",
    },
    {
      rank: "#2",
      name: [solgem2, "Land No. 55"],
      buildings: "27",
      hasBorder: true,
      visits: (
        <VuiBox ml={-1.325}>
          <VuiBadgeDot size="xs" badgeContent="x3,802" />
        </VuiBox>
      ),
      email: "alexa@simmmple.com",
      started: "04/01/22",
    },
    {
      rank: "#3",
      name: [solgem3, "Land No. 407"],
      buildings: "25",
      hasBorder: true,
      visits: (
        <VuiBox ml={-1.325}>
          <VuiBadgeDot color="white" size="xs" badgeContent="x3,202" />
        </VuiBox>
      ),
      started: "04/03/22",
    },
    {
      rank: "#4",
      name: [solgem4, "Land No. 406"],
      buildings: "15",
      hasBorder: true,
      visits: (
        <VuiBox ml={-1.325}>
          <VuiBadgeDot size="xs" color="white" badgeContent="x2,502" />
        </VuiBox>
      ),
      started: "04/05/22",
    },
    {
      rank: "#5",
      name: [solgems, "Land No. 544"],
      buildings: "13",
      hasBorder: true,
      visits: (
        <VuiBox ml={-1.325}>
          <VuiBadgeDot color="white" size="xs" badgeContent="x2,002" />
        </VuiBox>
      ),
      started: "04/05/22",
    },
  ],
};
