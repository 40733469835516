import { useState } from "react";
import { setGlobalState } from "context/globalstate";

// @material-ui core components
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

// Vision UI Dashboard React components
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";

// Images
import solgem from "assets/images/solsphere/phantom-wallet-logo.png";

//Buttons
import PhantomWalletIcon from "assets/images/solsphere/phantom-wallet-logo.png";

// API calss
import { getWalletLandIndexed } from "api/Solgem";

function WalletConnectButton() {
  const STORAGE_WALLET_ID =
    localStorage.getItem("WALLET_ID") == null ? "" : localStorage.getItem("WALLET_ID");

  const [walletId, setWalletId] = useState(STORAGE_WALLET_ID);

  const [isWalletAvalable, setIsWalletAvailable] = useState(STORAGE_WALLET_ID != "");

  // Snackbar
  const [anchorEl, setAnchorEl] = useState(null);
  const [show, setShow] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getProvider = () => {
    if ("solana" in window) {
      const provider = window.solana;
      if (provider.isPhantom) {
        return provider;
      }
    }
    window.open("https://phantom.app/", "_blank");
  };

  const handleWalletConnect = async () => {
    setShow(!show);
    try {
      const resp = await getProvider().connect();

      setIsWalletAvailable(true);
      setGlobalState("isWalletConnected", true);
      setWalletId(resp.publicKey.toString());

      localStorage.setItem("WALLET_ID", resp.publicKey.toString());
      setGlobalState("isLoggedIn", true);

      getWalletLandIndexed().then((response) => {
        setGlobalState("walletSolgems", response.solGemNFTIndexes);
        localStorage.setItem("SOLGEMS", JSON.stringify(response.solGemNFTIndexes));
      });
    } catch (err) {}
  };

  const disconnectWalletCtrl = () => {
    setIsWalletAvailable(false);
    setWalletId("");
    setGlobalState("isWalletConnected", false);
    window.location.reload();
    localStorage.setItem("WALLET_ID", "");
    localStorage.setItem("SOLGEMS", "[]");
  };

  return (
    <>
      <style>{`
              .element-visible { display: block }
              .element-hidden { display: none }
            `}</style>
      <VuiButton
        id="connectBtn"
        variant="contained"
        color="primary"
        onClick={handleWalletConnect}
        className={isWalletAvalable ? "element-hidden" : "element-visible"}
      >
        <img src={PhantomWalletIcon} width="15" alt="image" />
        <VuiTypography pl={1} variant="text" color="white" fontWeight="medium">
          CONNECT WALLET
        </VuiTypography>
      </VuiButton>
      {isWalletAvalable ? (
        <>
          <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
            <Tooltip title="Click to 'Copy' Wallet Id">
              <IconButton
                onClick={(event) => navigator.clipboard.writeText(event.currentTarget.textContent)}
              >
                <VuiTypography
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: 150,
                    whiteSpace: "nowrap",
                  }}
                  variant="text"
                  color="white"
                >
                  {walletId}
                </VuiTypography>
              </IconButton>
            </Tooltip>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar src={solgem} sx={{ width: 40, height: 40 }}></Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              sx: {
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                  my: 1,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>
              <Avatar src={solgem}> </Avatar>
              My account
            </MenuItem>
            <Divider />
            <MenuItem>
              <ListItemIcon>
                <Settings color="white" fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={disconnectWalletCtrl} sx={{ paddingBottom: 2 }}>
              <ListItemIcon>
                <Logout color="white" fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </>
      ) : null}
    </>
  );
}

export default WalletConnectButton;
