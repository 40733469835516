import fetchClient from "api/axios";

import { nofitySuccess } from "components/Toasts";

export const getElements = async () => {
  const response = await fetchClient.get(`/api/v1/land-elements`);
  return response.status === 200 ? response.data : [];
};

export const addElement = async (payload) => {
  await fetchClient.post(`/api/v1/land-elements`, payload).then((response) => {
    if (response.status === 201) {
      nofitySuccess(`New Land Element Added!`);
    } else {
    }
  });
};

export const deleteElements = async (id) => {
  await fetchClient.delete(`/api/v1/land-elements/${id}`).then((response) => {
    if (response.status === 204) {
      nofitySuccess(`Deleted Land Element!`);
    }
  });
};

export const updateElement = async (id, value) => {
  await fetchClient.patch(`/api/v1/land-elements${id}?value=${value}`).then((response) => {
    if (response.status === 200) {
      nofitySuccess(`Property ${response.data.name} changed!`);
    } else {
    }
  });
};
