import AdminNavbar from "examples/Navbars/AdminNavbar";
import React from "react";

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import breakpoints from "assets/theme/base/breakpoints";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "./data/dataTableData";
import PageLayout from "examples/LayoutContainers/PageLayout";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// icons
import { IoWallet } from "react-icons/io5";

function TopVisits() {
  const { values } = breakpoints;

  const [data, setData] = useState({
    columns: [
      { Header: "NAME", accessor: "name", width: "20%" },
      { Header: "POSITION", accessor: "position", width: "25%" },
      { Header: "OFFICE", accessor: "office" },
      { Header: "AGE", accessor: "age", width: "7%" },
      { Header: "START DATE", accessor: "startDate" },
      { Header: "SALARY", accessor: "salary" },
    ],

    rows: [
      {
        name: "Hanny Baniard",
        position: "Data Coordiator",
        office: "Baorixile",
        age: 42,
        startDate: "4/11/2021",
        salary: "$474,978",
      },
    ],
  });

  return (
    <>
      <PageLayout>
        <AdminNavbar />
        <Grid container justifyContent="center" mt={15} p={2}>
          <Grid item xs={9} lg={9}>
            <VuiBox mb={3} p={2}>
              <VuiTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
                color="white"
              >
                Top Visit Lands
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={9} lg={9}>
            <Card>
              <VuiBox p={3} pl={0} lineHeight={1}>
                <VuiTypography variant="h5" fontWeight="medium" color="white">
                  User Visits
                </VuiTypography>
                <VuiTypography variant="button" fontWeight="regular" color="text">
                  A lightweight, extendable, dependency-free javascript HTML table plugin.
                </VuiTypography>
              </VuiBox>
              <DataTable table={data} canSearch />
            </Card>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" mb={3}>
          <Footer />
        </Grid>
      </PageLayout>
    </>
  );
}

export default TopVisits;
