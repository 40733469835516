import Footer from "examples/Footer";
import PageLayout from "examples/LayoutContainers/PageLayout";
import AdminNavbar from "examples/Navbars/AdminNavbar";
import React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ComplexProjectCard from "examples/Cards/ProjectCards/ComplexProjectCard";

// icons
import { IoWallet } from "react-icons/io5";
import Welcome from "../layouts/Welcome";
import ActiveUsers from "../layouts/ActiveUsers";
import CreditBalance from "./components/CreditBalance";

function Dashboard() {
  const { values } = breakpoints;

  return (
    <>
      <PageLayout>
        <AdminNavbar />
        <Grid container justifyContent="center" mt={15} p={1}>
          <Grid item xs={9} lg={9}>
            <VuiBox mb={3} p={2}>
              <VuiTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
                color="white"
              >
                Dashboard
              </VuiTypography>
            </VuiBox>
            <Grid container>
              <Grid item xs={12} md={6} lg={6} xl={6} p={1}>
                <VuiBox mb={3}>
                  <MiniStatisticsCard
                    title={{ text: "today's money", fontWeight: "bold" }}
                    count="$53,000"
                    percentage={{ color: "success", text: "+55%" }}
                    icon={<IoWallet color="white" />}
                  />
                </VuiBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6} p={1}>
                <VuiBox mb={3}>
                  <MiniStatisticsCard
                    title={{ text: "today's money", fontWeight: "bold" }}
                    count="$53,000"
                    percentage={{ color: "success", text: "+55%" }}
                    icon={<IoWallet color="white" />}
                  />
                </VuiBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6} lg={6} xl={6} p={1.5}>
                <VuiBox mb={3}>
                  <Welcome />
                </VuiBox>
                <Grid item xs={12} md={12} lg={12}>
                  <MiniStatisticsCard
                    title={{ fontWeight: "medium", text: "today's money" }}
                    count="$53,000"
                    percentage={{ color: "success", text: "+55%" }}
                    icon={<IoWallet color="white" />}
                    direction="right"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6} p={1.5}>
                <VuiBox mb={3}>
                  <ActiveUsers />
                </VuiBox>
              </Grid>
              <Grid item xs={4} lg={4} p={2}>
                <ProfileInfoCard
                  title="profile information"
                  description="Hi, I’m Mark Johnson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
                  info={{
                    fullName: "Mark Johnson",
                    mobile: "(44) 123 1234 123",
                    email: "mark@simmmple.com",
                    location: "United States",
                  }}
                  social={[
                    {
                      link: "https://www.facebook.com/CreativeTim/",
                      icon: <FacebookIcon />,
                      color: "facebook",
                    },
                    {
                      link: "https://twitter.com/creativetim",
                      icon: <TwitterIcon />,
                      color: "twitter",
                    },
                    {
                      link: "https://www.instagram.com/creativetimofficial/",
                      icon: <InstagramIcon />,
                      color: "instagram",
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={4} lg={4} p={2}>
                <ComplexProjectCard
                  title="slack bot"
                  color="info"
                  description="If everything I did failed - which it doesn't, I think that it actually succeeds."
                  dateTime="02.03.22"
                />
              </Grid>
              <Grid item xs={4} lg={4} p={2}>
                <CreditBalance />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" mb={3}>
          <Footer />
        </Grid>
      </PageLayout>
    </>
  );
}

export default Dashboard;
