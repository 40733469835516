import React from "react";

// @mui material components
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Vision UI Dashboard PRO React examples
import Globe from "examples/Globe";

// Dashboard layout components
import SolgemBanner from "assets/images/solgems.jpg";

const style = {
  background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
  border: 0,
  borderRadius: 3,
  boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
  color: "white!important",
  height: 48,
  padding: "0 30px",
  marginLeft: 2,
};
function Dashboard() {
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid container>
        <Grid item lg={6} md={12} xs={12}>
          <Card>
            <CardMedia component="img" height="500" image={SolgemBanner} />
            <CardContent sx={{ marginTop: 2 }}>
              <Typography
                gutterBottom
                variant="h4"
                component="div"
                sx={{ color: "white!important" }}
              >
                Solgems
              </Typography>
              <Typography variant="body2" sx={{ color: "white!important" }}>
                SolGems Are A Collection Of 886 Hand Drawn Gems That Serve As Your Digital Passport
                To Solsphere Digital Land. 1 SolGem = 1 Plot Of Land In Solsphere.
              </Typography>
            </CardContent>
            <CardActions>
              <Link
                href="https://magiceden.io/marketplace/sol_gems"
                key="link-to-view-magic-eden"
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: "none" }}
              >
                <Button sx={style}>VIEW MAGIC EDEN</Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item md={6} xs={6}>
          <Globe
            display={{ xs: "none", md: "none", lg: "block" }}
            mt={{ xs: -12, lg: 1 }}
            mr={{ xs: 0, md: -10, lg: 10 }}
            canvasStyle={{ marginTop: "3rem" }}
          />
        </Grid>
      </Grid>

      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
