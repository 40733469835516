import AdminNavbar from "examples/Navbars/AdminNavbar";
import React, { useEffect } from "react";

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiModal from "components/VuiModal";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import breakpoints from "assets/theme/base/breakpoints";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import PageLayout from "examples/LayoutContainers/PageLayout";

import { getElements, addElement, deleteElements } from "../api/landelements";
import VuiInput from "components/VuiInput";

function TopVisits() {
  const { values } = breakpoints;

  const [data, setData] = useState({
    columns: [
      {
        Header: "ID",
        accessor: "id",
        width: "5%",
        Cell: ({ value }) => (
          <VuiButton
            variant="outlined"
            color="error"
            size="small"
            iconOnly
            circular
            sx={({ functions: { pxToRem } }) => ({
              width: pxToRem(34),
              minWidth: pxToRem(34),
              height: pxToRem(34),
              minHeight: pxToRem(34),
            })}
            onClick={(e) => handleDeleteElement(e, value)}
          >
            <Icon>delete</Icon>
          </VuiButton>
        ),
      },
      { Header: "Building Type", accessor: "buildingType" },
      { Header: "Building Multiplier", accessor: "buildingMultiplier", width: "7%" },
      {
        Header: "Reference Link",
        accessor: "referenceLink",
        Cell: ({ value }) => (
          <a href={value} target="_blank">
            <VuiTypography variant="body2" color="white" href={value}>
              {value}
            </VuiTypography>
          </a>
        ),
      },
    ],

    rows: [],
  });

  const [refreshTrigger, setRefreshTrigger] = useState(1);

  const [link, setLink] = useState();
  const [type, setType] = useState();
  const [multiplier, setMultiplier] = useState();

  const getLandElements = () => {
    getElements().then((response) => {
      setData({ ...data, rows: response });
    });
  };

  const submitForm = () => {
    addElement({
      referenceLink: link,
      buildingType: type,
      buildingMultiplier: multiplier,
    });

    setRefreshTrigger(refreshTrigger + 1);
  };

  const handleDeleteElement = (e, id) => {
    e.preventDefault();

    deleteElements(id);
    setRefreshTrigger(refreshTrigger + 1);
    window.location.reload();
  };

  useEffect(() => {
    getLandElements();
  }, [refreshTrigger]);

  const addContent = () => {
    return (
      <>
        <VuiBox sx={{ mb: 2 }}>
          <VuiTypography variant="h6" color="white">
            Reference Link
          </VuiTypography>
          <VuiInput type="text" placeholder="Link" onChange={(e) => setLink(e.target.value)} />
        </VuiBox>
        <VuiBox sx={{ mb: 2 }}>
          <VuiTypography variant="h6" color="white">
            Building Type
          </VuiTypography>
          <VuiSelect
            placeholder="Select year"
            options={[
              { value: "AIRPORT", label: "Airport" },
              { value: "AMHITHEATER", label: "Amhitheater" },
              { value: "ANGKOR_WAT", label: "Angkor wat" },
              { value: "FERRIS_WHEEL", label: "Ferris wheel" },
            ]}
            onChange={(choice) => setType(choice.value)}
          />
        </VuiBox>
        <VuiBox sx={{ mb: 2 }}>
          <VuiTypography variant="h6" color="white">
            Building Multiplier
          </VuiTypography>
          <VuiInput
            type="number"
            placeholder="Multiplier"
            onChange={(e) => setMultiplier(e.target.value)}
          />
        </VuiBox>
      </>
    );
  };

  return (
    <>
      <PageLayout>
        <AdminNavbar />
        <Grid container justifyContent="center" mt={15} p={2}>
          <Grid item xs={9} lg={9}>
            <VuiBox mb={3} p={2}>
              <VuiTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
                color="white"
              >
                Building References
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={9} lg={9}>
            <Card>
              <VuiBox p={1} pl={0} lineHeight={1}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <VuiTypography variant="h5" fontWeight="medium" color="white">
                      User Visits
                    </VuiTypography>
                    <VuiTypography variant="button" fontWeight="regular" color="text">
                      A lightweight, extendable, dependency-free javascript HTML table plugin.
                    </VuiTypography>
                  </Grid>
                  <Grid item>
                    <VuiModal
                      title="Add Building Element"
                      content={addContent()}
                      submitForm={submitForm}
                    />
                  </Grid>
                </Grid>
              </VuiBox>
              <DataTable table={data} canSearch />
            </Card>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" mb={3}>
          <Footer />
        </Grid>
      </PageLayout>
    </>
  );
}

export default TopVisits;
