import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Card } from "@mui/material";
import Link from "@mui/material/Link";
import WrongLocationIcon from "@mui/icons-material/WrongLocation";
import TwitterIcon from "@mui/icons-material/Twitter";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Web GL for react
import Unity, { UnityContext } from "react-unity-webgl";

// Vision UI Dashboard React components
import VuiButton from "components/VuiButton";

// image stock
import RunningSolGem from "assets/images/running-solgem.gif";
import LoadingScreen from "assets/images/loading-screen.png";
import BrandBanner from "assets/images/brand-banner.jpg";
import MaintenanceBanner from "assets/images/maintenance-screen.webp";

// Vision UI Dashboard React components
import VuiProgress from "components/VuiProgress";
import SolgemCard from "./components/SolgemCard";

// API Calls
import { getSolgemWalletMintAddressByIndex } from "api/Solgem";
import { getMaintenanceModeProperty } from "api/property";

const EMPTY_STRING = "";

const BUILD_CONTEXT = (url) => {
  return process.env.REACT_APP_API + "/build/" + url;
};

const unityContext = new UnityContext({
  loaderUrl: BUILD_CONTEXT("game.loader.js"),
  dataUrl: BUILD_CONTEXT("data"),
  frameworkUrl: BUILD_CONTEXT("framework.js"),
  codeUrl: BUILD_CONTEXT("wasm"),
});

function isNumber(n) {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}

function Game() {
  let { id } = useParams();

  const [isValidIndex, setIsValidIndex] = useState(true);
  const [isOnMaintenanceMode, setIsOnMaintenanceMode] = useState(false);
  const [cardBackground, setCardBackground] = useState(LoadingScreen);

  if (id == null) {
    id = 0;
  }

  localStorage.setItem("LAND_INDEX", id);

  const [progression, setProgression] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const isLandIndexZero = id == 0;

  const [solgemMintAddress, setSolgemMintAddress] = useState(EMPTY_STRING);
  const [solgemImageLink, setSolgemImageLink] = useState(EMPTY_STRING);

  useEffect(() => {
    let isOnMaintenance = false;

    getMaintenanceModeProperty().then((response) => {
      isOnMaintenance = response?.value === "true";
      setIsOnMaintenanceMode(isOnMaintenance);
      if (isOnMaintenance) {
        setCardBackground(MaintenanceBanner);
      }
    });

    if (!isNumber(id)) {
      setIsValidIndex(false);
    } else if (id > 886) {
      setIsValidIndex(false);
    }

    if (solgemMintAddress == EMPTY_STRING) {
      getSolgemWalletMintAddressByIndex(id).then((result) => {
        setSolgemMintAddress(result.mintAddress);
        setSolgemImageLink(result.solgemImageLink);
      });
    }

    unityContext.on("progress", function (progression) {
      setProgression(progression);
    });
    unityContext.on("loaded", function () {
      setIsLoaded(true);
    });
    // unityContext.on("debug", function (message) {
    //   console.log(message);
    // });
    (function anonymous() {
      debugger;
    });

    if (localStorage.getItem("WALLET_ID") == null) {
      localStorage.setItem("WALLET_ID", "");
    }
  }, []);

  const handleOnClickFullscreen = () => {
    unityContext.setFullscreen(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox mb={1}>
        <Grid container>
          <Grid item xs={12} lg={9} xl={9}>
            <Card
              sx={() => ({
                height: "680px",
                width: "100%",
                background: "url(" + cardBackground + ")",
                backgroundColor: "#00cde4",
                backgroundSize: "cover",
                backgroundPosition: "50%",
                boxShadow: "0 3px 32px rgb(0 231 234)",
                padding: "1px 1px",
                margin: "10px 0 0 10px",
              })}
            >
              <VuiBox
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                {isValidIndex ? (
                  <>
                    {!isLoaded && !isOnMaintenanceMode && (
                      <VuiBox
                        sx={{
                          marginTop: "500px!important",
                          mx: "auto",
                          width: 600,
                        }}
                      >
                        <Grid item xs={12} lg={12} xl={12}>
                          <img
                            style={{
                              marginLeft: "" + Math.round(progression * 100) + "%",
                            }}
                            src={RunningSolGem}
                            loading="lazy"
                            width={100}
                          />
                        </Grid>
                        <VuiProgress
                          sx={{ marginTop: "10px!important" }}
                          variant="gradient"
                          value={Math.round(progression * 100)}
                        />
                        <VuiTypography
                          variant="h4"
                          color="white"
                          sx={{ marginTop: "0px!important" }}
                        >
                          Loading land engine {Math.round(progression * 100)} %
                        </VuiTypography>
                        ;
                      </VuiBox>
                    )}
                    <Unity
                      unityContext={unityContext}
                      style={{
                        visibility: isLoaded ? "visible" : "hidden",
                        height: "100%",
                        width: "100%",
                        border: "2px solid #00cde4",
                        background: "#00cde4",
                        borderRadius: "20px",
                        padding: "5px 5px",
                        visibility: isLoaded ? "visible" : "hidden",
                      }}
                    />
                  </>
                ) : (
                  <VuiBox
                    sx={{
                      marginTop: "200px!important",
                      mx: "auto",
                      textAlign: "center",
                    }}
                  >
                    <Grid item xs={12} lg={12} xl={12}>
                      <VuiTypography
                        variant="h6"
                        color="white"
                        textTransform="capitalize"
                        fontWeight="bold"
                        sx={{ fontSize: "50px" }}
                      >
                        <WrongLocationIcon /> Looks like your missing
                      </VuiTypography>
                      <VuiTypography
                        variant="h6"
                        color="white"
                        textTransform="capitalize"
                        fontWeight="bold"
                        sx={{ fontSize: "50px" }}
                      >
                        Please check world map
                      </VuiTypography>
                    </Grid>
                  </VuiBox>
                )}
              </VuiBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <VuiBox mx={5} my={1}>
              <Grid container>
                <Grid item xs={12} lg={12} xl={12}>
                  {isValidIndex ? (
                    <VuiBox>
                      {isLandIndexZero ? null : (
                        <Link
                          href={"https://magiceden.io/item-details/" + solgemMintAddress}
                          target="_blank"
                          rel="noreferrer"
                          sx={{ textDecoration: "none" }}
                        >
                          <SolgemCard
                            imageLink={solgemImageLink}
                            cardTitle={"SOLGEM"}
                            hasViewLink={true}
                          />
                        </Link>
                      )}

                      <VuiButton
                        sx={() => ({ margin: "10px 0 0 0px" })}
                        onClick={handleOnClickFullscreen}
                        color="dark"
                        fullWidth
                      >
                        <Icon>expand</Icon>&nbsp; FULLSCREEN
                      </VuiButton>

                      <Grid container>
                        <Grid item xs={12} lg={6} xl={6} mt={1} pr={1}>
                          <Link
                            href="https://magiceden.io/marketplace/sol_gems"
                            key="link-to-solgem-marketplace"
                            target="_blank"
                            rel="noreferrer"
                            sx={{ textDecoration: "none" }}
                          >
                            <VuiButton color="dark" fullWidth>
                              <Icon>store</Icon> MARKETPLACE
                            </VuiButton>
                          </Link>
                        </Grid>
                        <Grid item xs={12} lg={6} xl={6} mt={1}>
                          <Link
                            href={"https://solscan.io/token/" + solgemMintAddress}
                            key="link-to-view-solscan"
                            target="_blank"
                            rel="noreferrer"
                            sx={{ textDecoration: "none" }}
                          >
                            <VuiButton color="dark" fullWidth>
                              <Icon>store</Icon>SOLSCAN
                            </VuiButton>
                          </Link>
                        </Grid>
                      </Grid>
                    </VuiBox>
                  ) : null}
                </Grid>
                <Grid item xs={12} lg={6} xl={6} mt={1} pr={1}>
                  <Link
                    href="https://twitter.com/SolSphereNFT"
                    key="link-to-view-solscan"
                    target="_blank"
                    rel="noreferrer"
                    sx={{ textDecoration: "none" }}
                  >
                    <VuiButton variant="gradient" color="info" fullWidth>
                      <TwitterIcon />
                      &nbsp; Twitter
                    </VuiButton>
                  </Link>
                </Grid>
                <Grid item xs={12} lg={6} xl={6} mt={1}>
                  <Link
                    href="https://discord.com/invite/kMYkYVEGPu"
                    key="link-to-view-solscan"
                    target="_blank"
                    rel="noreferrer"
                    sx={{ textDecoration: "none" }}
                  >
                    <VuiButton variant="gradient" color="primary" fullWidth>
                      <Icon>chat</Icon>&nbsp; Discord
                    </VuiButton>
                  </Link>
                </Grid>
              </Grid>
            </VuiBox>
            {/* <VuiBox mx={5} my={1}>
              <Grid container>
                <Grid item xs={12} lg={12} xl={12} mt={1}>
                  <VuiBox>
                    <Link href={""} rel="noreferrer" sx={{ textDecoration: "none" }}>
                      <SolgemCard isForAdds imageLink={BrandBanner} cardTitle={"Your brand here"} />
                    </Link>
                  </VuiBox>
                </Grid>
              </Grid>
            </VuiBox> */}
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Game;
