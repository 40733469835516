import { useState } from "react";

import { useHistory } from "react-router-dom";

import axios from "axios";
import qs from "qs";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";
import VuiButton from "components/VuiButton";
// Vision UI Dashboard custom components
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Images
import Image from "assets/images/solgem_sample.png";

// Authentication layout components
import IllustrationLayout from "../layouts/IllustrationLayout";

let PROXY = process.env.REACT_APP_API;

function Login() {
  const [rememberMe, setRememberMe] = useState(false);

  const history = useHistory();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();

    axios({
      method: "post",
      url: PROXY + "/login",
      data: qs.stringify({
        username: username,
        password: password,
      }),
      headers: {
        "content-type": "application/x-www-form-urlencoded; charset=utf-8",
      },
    })
      .then((response) => {
        if (response.status === 200 && response.data[0].statusCode == "OK") {
          history.push("/admin/dashboard");
        }
      })
      .catch((err) => {
        setErrorMessage(true);
      });
  };

  return (
    <IllustrationLayout
      title="Log In"
      description="Enter your username and password"
      illustration={{
        image: Image,
      }}
      top={20}
    >
      {errorMessage && (
        <VuiTypography variant="body2" color="error">
          Your email or password is wrong{" "}
        </VuiTypography>
      )}

      <VuiBox component="form" role="form">
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Username
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              onChange={(e) => setUsername(e.target.value)}
              type="email"
              placeholder="Your username..."
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Password
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Your password..."
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox display="flex" alignItems="center">
          <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe} />
          <VuiTypography
            variant="caption"
            color="white"
            fontWeight="medium"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Remember me
          </VuiTypography>
        </VuiBox>
        <VuiBox mt={4} mb={1}>
          <VuiButton color="info" onClick={handleLogin} fullWidth>
            SIGN IN
          </VuiButton>
        </VuiBox>
      </VuiBox>
    </IllustrationLayout>
  );
}

export default Login;
