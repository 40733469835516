import React from "react";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Data
import tableData from "./data";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Table from "examples/Tables/Table";

function Leaderboards() {
  const { columns, rows } = tableData;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ height: "100%", mb: 10 }}>
        <VuiBox mb="24px">
          <VuiTypography variant="lg" fontWeight="bold" color="white">
            Leaderboards
          </VuiTypography>
        </VuiBox>
        <VuiBox>
          <Table columns={columns} rows={rows} />
        </VuiBox>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Leaderboards;
