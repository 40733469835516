import React from "react";
import AdminNavbar from "examples/Navbars/AdminNavbar";
import Card from "@mui/material/Card";
import PageLayout from "examples/LayoutContainers/PageLayout";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Footer from "examples/Footer";
import { Grid } from "@mui/material";

import Table from "examples/Tables/Table";
import breakpoints from "assets/theme/base/breakpoints";

import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// icons
import { IoWallet } from "react-icons/io5";

// Data
import tableData from "./data";

function UserLoginTable() {
  const { columns, rows } = tableData;
  const { values } = breakpoints;
  return (
    <>
      <PageLayout>
        <AdminNavbar />
        <Grid container justifyContent="center" mt={15} p={2}>
          <Grid item xs={9} lg={9}>
            <VuiBox mb={3} p={2}>
              <VuiTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
                color="white"
              >
                User Login
              </VuiTypography>
            </VuiBox>
            <Grid container>
              <Grid item xs={12} md={6} lg={6} xl={6} p={2}>
                <VuiBox mb={3}>
                  <MiniStatisticsCard
                    title={{ text: "today's money", fontWeight: "bold" }}
                    count="$53,000"
                    percentage={{ color: "success", text: "+55%" }}
                    icon={<IoWallet color="white" />}
                  />
                </VuiBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6} p={2}>
                <VuiBox mb={3}>
                  <MiniStatisticsCard
                    title={{ text: "today's money", fontWeight: "bold" }}
                    count="$53,000"
                    percentage={{ color: "success", text: "+55%" }}
                    icon={<IoWallet color="white" />}
                  />
                </VuiBox>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={9} md={12} xs={12}>
            <VuiBox p={2}>
              <Card sx={{ height: "100%", mb: 10 }}>
                <VuiBox mb="24px">
                  <VuiTypography variant="lg" fontWeight="bold" color="white">
                    User Login
                  </VuiTypography>
                </VuiBox>
                <VuiBox>
                  <Table columns={columns} rows={rows} />
                </VuiBox>
              </Card>
            </VuiBox>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" mb={3}>
          <Footer />
        </Grid>
      </PageLayout>
    </>
  );
}

export default UserLoginTable;
