// Vision UI Dashboard React layouts
import World from "./world";
import Marketplace from "./marketplace";
import Leaderboards from "./leaderboards";

import Dashboard from "layouts/admin/dashboard";
import BuildingTable from "layouts/admin/buildingtable";
import Settings from "layouts/admin/settings";
import TopVisits from "layouts/admin/topvisits";
import UserLoginTable from "layouts/admin/userlogintable";
import Login from "layouts/admin/login";

// Vision UI Dashboard React icons
import { IoGlobe } from "react-icons/io5";
import { IoTrophy } from "react-icons/io5";
import { IoStorefront } from "react-icons/io5";

const routes = [
  {
    type: "collapse",
    name: "World",
    key: "world",
    route: "/game/world",
    icon: <IoGlobe size="15px" color="inherit" />,
    component: World,
    noCollapse: true,
  },
  {
    name: "Login",
    key: "login",
    route: "/login",
    component: Login,
    invisible: true,
  },
  {
    name: "Dashboard",
    key: "dashboard",
    route: "/admin/dashboard",

    component: Dashboard,
    invisible: true,
  },
  {
    name: "Building Table",
    key: "buildingtable",
    route: "/admin/building-table",

    component: BuildingTable,
    invisible: true,
  },
  {
    name: "Settings",
    key: "settings",
    route: "/admin/settings",
    component: Settings,
    invisible: true,
  },
  {
    name: "Top Visits",
    key: "topvisits",
    route: "/admin/top-visits",
    component: TopVisits,
    invisible: true,
  },
  {
    name: "User Login",
    key: "userlogin",
    route: "/admin/user-login",
    component: UserLoginTable,
    invisible: true,
  },
  {
    type: "collapse",
    name: "Leaderboards",
    key: "leaderboards",
    route: "/game/leaderboards",
    icon: <IoTrophy size="15px" color="inherit" />,
    component: Leaderboards,
    noCollapse: true,
  },
  { type: "divider", key: "solgems" },
  { type: "title", title: "SolGems", key: "account-pages" },
  {
    type: "collapse",
    name: "Marketplace",
    key: "marketplace",
    route: "/game/marketplace",
    icon: <IoStorefront size="15px" color="inherit" />,
    component: Marketplace,
    noCollapse: true,
  },
];

export default routes;
