// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "examples/Footer";

// Vision UI Dashboard PRO React theme functions
import colors from "assets/theme/base/colors";

// Vision UI Dashboard PRO React theme functions
import linearGradient from "assets/theme/functions/linearGradient";
import tripleLinearGradient from "assets/theme/functions/tripleLinearGradient";

function IllustrationLayout({ title, description, illustration, children, top }) {
  const { gradients } = colors;
  return (
    <PageLayout
      background={tripleLinearGradient(
        gradients.cover.main,
        gradients.cover.state,
        gradients.cover.stateSecondary,
        gradients.cover.angle
      )}
    >
      <VuiBox
        height="100%"
        width="50vw"
        display={{ xs: "none", md: "block" }}
        position="absolute"
        top={0}
        right={0}
        sx={({ breakpoints }) => ({
          overflow: "hidden",
          [breakpoints.down("xl")]: {
            ml: "100px",
          },
          [breakpoints.down("lg")]: {
            display: "none",
          },
        })}
        zIndex={0}
      >
        <VuiBox
          display={{ xs: "none", lg: "flex" }}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="calc(100%)"
          height="calc(100%)"
          position="relative"
          textAlign="center"
          variant="gradient"
          m={0}
          px={{ xs: 13, lg: 6, xl: 13 }}
          sx={{
            overflow: "hidden",
            background: linearGradient(
              gradients.info.main,
              gradients.info.state,
              gradients.info.deg
            ),
          }}
        >
          {illustration.image && (
            <VuiBox
              component="img"
              src={illustration.image}
              alt="chat-illustration"
              width="100%"
              maxWidth="100%"
            />
          )}
        </VuiBox>
      </VuiBox>

      <VuiBox
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          alignItems: "center",
          maxWidth: "1044px",
          minHeight: "75vh",
          margin: "0 auto",
        }}
      >
        <VuiBox
          mt={top}
          mr="auto !important"
          sx={({ breakpoints }) => ({
            [breakpoints.down("xl")]: {
              ml: "30px",
            },
            [breakpoints.down("lg")]: {
              ml: "auto",
              mr: "auto !important",
            },
            [breakpoints.down("md")]: {
              maxWidth: "90%",
              pl: "7px",
              pr: "10px !important",
            },
          })}
        >
          <VuiBox
            pt={3}
            pr={3}
            pl={{ xs: 3, xl: 2 }}
            mx={{ xs: "auto !important", xl: "unset !important" }}
            maxWidth={"350px"}
          >
            <>
              <VuiBox mb="35px">
                <VuiTypography
                  textAlign={"start"}
                  variant="h3"
                  fontWeight="bold"
                  color="white"
                  mb="10px"
                >
                  {title}
                </VuiTypography>
                <VuiTypography
                  textAlign={"start !important"}
                  mx="auto"
                  sx={({ typography: { size }, functions: { pxToRem } }) => ({
                    fontWeight: "regular",
                    fontSize: size.sm,
                  })}
                  color="white"
                >
                  {description}
                </VuiTypography>
              </VuiBox>
            </>
          </VuiBox>

          <VuiBox
            pl={{ xs: 3, xl: 1 }}
            pr={3}
            mb="50px"
            mx="auto"
            ml="auto !important"
            sx={({ breakpoints }) => ({
              mt: { top },
              maxWidth: "350px",
              [breakpoints.down("xl")]: {
                mr: "100px",
              },
              [breakpoints.up("lg")]: {
                mr: "95px",
              },
              [breakpoints.only("lg")]: {
                mr: "auto",
                ml: "auto !important",
              },
              [breakpoints.down("lg")]: {
                mr: "auto",
                ml: "auto !important",
              },
              [breakpoints.down("md")]: {
                mr: "unset",
                pr: "7px",
                pl: "10px !important",
              },
            })}
          >
            {children}
          </VuiBox>

          <Footer />
        </VuiBox>
      </VuiBox>
    </PageLayout>
  );
}

// Setting default values for the props of IllustrationLayout
IllustrationLayout.defaultProps = {
  color: "info",
  header: "",
  title: "",
  description: "",
  illustration: {},
};

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  illustration: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default IllustrationLayout;
