import toast from "react-hot-toast";

export const nofitySuccess = (message) => {
  toast.success("Success! \n" + message, {
    style: {
      borderLeft: "5px solid #ffffff",
      padding: "14px",
      color: "#faf6ff",
      backgroundColor: "#01b574",
      fontWeight: 300,
    },
    iconTheme: {
      primary: "#e1efcb",
      secondary: "#3da116",
    },
    duration: 6000,
  });
};

export const notifyDanger = (message) => {
  toast.error("Error! \n" + message, {
    style: {
      borderLeft: "10px solid #fd375a",
      padding: "18px",
      fontWeight: 400,
    },
    iconTheme: {
      primary: "#fd375a",
      secondary: "#fff",
    },
    duration: 6000,
  });
};
