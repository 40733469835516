import { useGlobalState } from "context/globalstate";

// MUI
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import ImageList from "@mui/material/ImageList";
import ListSubheader from "@mui/material/ListSubheader";

import { useMediaQuery } from "react-responsive";

export default function SolLands() {
  let landIndexRange = 1;
  const isLaptop = useMediaQuery({ query: `(max-width: 1024px)` });

  const [walletSolgems] = useGlobalState("walletSolgems");
  const [openLandCard] = useGlobalState("openLandCard");

  const arrayOf100 = () => {
    const landCounts = [];

    const landLoopCount = openLandCard == "snow" ? 116 : 110;

    for (let i = 1; i <= landLoopCount; ++i) {
      landCounts.push(i);
    }
    return landCounts;
  };

  const itemData = (landSelected) => {
    return arrayOf100().map((index) => {
      return {
        img:
          process.env.PUBLIC_URL +
          "/land/" +
          landSelected +
          "/" +
          landSelected +
          " (" +
          index +
          ").png",
        count: index,
      };
    });
  };

  switch (openLandCard) {
    case "moon":
      landIndexRange = 111;
      break;
    case "volcano":
      landIndexRange = 221;
      break;
    case "rock":
      landIndexRange = 331;
      break;
    case "sand":
      landIndexRange = 441;
      break;
    case "gem":
      landIndexRange = 551;
      break;
    case "beach":
      landIndexRange = 661;
      break;
    case "snow":
      landIndexRange = 771;
      break;
    default:
  }

  return (
    <ImageList sx={{ width: isLaptop ? 800 : 1100, height: 600 }} cols={10}>
      <ImageListItem variant="masonry" key="SolLands" cols={10} gap={8}>
        <ListSubheader component="div">
          Lands for {openLandCard} |
          <IconButton sx={{ color: "#00e7ea" }} aria-label={`info about `}>
            <Icon>stars</Icon>
          </IconButton>
          Rare lands |
          <IconButton sx={{ color: "#00e7ea" }} aria-label={`info about `}>
            <Icon>light</Icon>
          </IconButton>
          Your lands are in highlight
        </ListSubheader>
      </ImageListItem>
      {itemData(openLandCard).map((item) => (
        <ImageListItem key={item.img}>
          <Link key={"image_" + item} href={`/${landIndexRange}`} to={0}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              width={100}
            />
            <ImageListItemBar
              title={landIndexRange++}
              sx={{
                background: walletSolgems.includes(landIndexRange - 1)
                  ? "#00e7ea"
                  : "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                marginRight: 0.5,
              }}
              actionIcon={
                <IconButton sx={{ color: "#ffffff" }} aria-label={`info about `}>
                  {/* {walletSolgems.includes(landIndexRange - 1) ? <Icon>diamond</Icon> : null} */}
                  {rareLands().includes(landIndexRange - 1) ? <Icon>stars</Icon> : null}
                </IconButton>
              }
            />
          </Link>
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const rareLands = () => {
  return [
    719, 298, 685, 46, 388, 62, 399, 55, 766, 669, 73, 448, 850, 407, 647, 878, 370, 755, 748, 356,
    848, 25, 435, 398, 541, 173, 275, 825, 406, 334, 121, 83, 408, 822, 552, 144, 646, 816, 166,
    610, 92, 439, 585, 216, 410, 200, 255, 450, 454, 786, 649, 751, 6, 513, 695, 834, 139, 797, 633,
    129, 19, 155, 730, 308, 432, 858, 142, 500, 75, 668, 880, 28, 820, 625, 480, 727, 496, 366, 463,
    315, 656, 348, 618, 672, 529, 581,
  ];
};
