import React from "react";

import { Card, Icon } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import AdComponent from "./AdComponent";

const SolgemCard = ({ imageLink, cardTitle, hasViewLink, isForAdds }) => {
  return (
    <VuiBox>
      {isForAdds ? <AdComponent /> : null}

      <Card
        sx={{
          background:
            "linear-gradient(127.09deg, rgb(0 0 0 / 21%) 19.41%, rgb(10 14 35 / 0%) 76.65%), url(" +
            imageLink +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "50%",
          backgroundColor: "#00cde4",
          border: "3px solid #00cde4",
          boxShadow: "0 3px 32px rgb(0 231 234)",
        }}
      >
        <VuiBox height="100%" display="flex" flexDirection="column" justifyContent="space-between">
          <VuiBox mb="150px">
            <VuiTypography color="white" variant="h4" fontWeight="regular" mb="15px">
              {cardTitle}
            </VuiTypography>
          </VuiBox>
          {hasViewLink ? (
            <VuiTypography
              variant="button"
              color="white"
              fontWeight="regular"
              sx={{
                mr: "5px",
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translate(2px, -0.5px)`,
                  transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                },

                "&:hover .material-icons-round, &:focus  .material-icons-round": {
                  transform: `translate(6px, -0.5px)`,
                },
              }}
            >
              View SOLGEM on magic eden
              <Icon sx={{ fontWeight: "bold", ml: "5px" }}>arrow_forward</Icon>
            </VuiTypography>
          ) : null}
        </VuiBox>
      </Card>
    </VuiBox>
  );
};

export default SolgemCard;
