import axios from "axios";

export const fetchClient = (token) => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Create an instance using the config defaults provided by the library
  // At this point the timeout config value is `0` as is the default for the library
  let instance = axios.create(defaultOptions);

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      // Do something with response error
      return Promise.reject(error);
    }
  );

  return instance;
};

export default fetchClient();
