import SolBeach from "assets/images/sol-beach.png";
import SolGem from "assets/images/sol-gem.png";
import SolMoon from "assets/images/sol-moon.png";
import SolRock from "assets/images/sol-rock.png";
import SolSand from "assets/images/sol-sand.png";
import SolSnow from "assets/images/sol-snow.png";
import SolVolcano from "assets/images/sol-volcano.png";
import SolGrass from "assets/images/sol-grass.png";

export default [
  {
    id: 1,
    city: "GRASS: 1 - 110",
    color: "green",
    coordinates: [-20, -30.431297],
    description:
      "A large group of green plants with jointed stems, long slender leaves, and stalks of clustered flowers.",
    value: 0,
    path: "grass",
    image: SolGrass,
  },
  {
    id: 2,
    city: "MOON: 111 - 220",
    color: "grey",
    coordinates: [39.9042, 116.4074],
    description: "The Moon natural satellite and the fifth largest moon in the solar system",
    value: 0,
    path: "moon",
    image: SolMoon,
  },
  {
    id: 3,
    city: "VOLCANO: 221 - 330",
    color: "brown",
    coordinates: [-20.5074, 95.1278],
    description:
      "A land mountain that opens downward to a pool of molten rock below the surface of the earth.",
    value: 0,
    path: "volcano",
    image: SolVolcano,
  },
  {
    id: 4,
    city: "ROCK: 331 - 440",
    color: "brown",
    coordinates: [5.5074, -120.1278],
    description:
      "Sol rock is a natural substance composed of solid crystals of different minerals that have been fused together into a solid lump.",
    value: 0,
    path: "rock",
    image: SolRock,
  },
  {
    id: 5,
    city: "SAND: 441 - 550",
    color: "#917d6e",
    coordinates: [100, -73.935242],
    description:
      "Sand is a loose granular material blanketing the beaches, riverbeds and deserts of the world.",
    value: 0,
    path: "sand",
    image: SolSand,
  },
  {
    id: 6,
    city: "GEM: 551 - 660",
    color: "#5b256d",
    coordinates: [35.5074, -50.1278],
    description: "Land of precious or sometimes semiprecious stone cut and polished for ornament",
    value: 0,
    path: "gem",
    image: SolGem,
  },
  {
    id: 7,
    city: "BEACH: 661 - 770",
    color: "blue",
    coordinates: [9, 30],
    description:
      "A beach is a narrow, gently sloping strip of land that lies along the edge of an ocean, lake, or river.",
    value: 0,
    path: "beach",
    image: SolBeach,
  },
  {
    id: 8,
    city: "SNOW: 771 - 886",
    color: "white",
    coordinates: [-81.5074, 580.1278],
    description: "Snow is precipitation in the form of ice crystals.",
    value: 0,
    path: "snow",
    image: SolSnow,
  },
];
