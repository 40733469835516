import AdminNavbar from "examples/Navbars/AdminNavbar";
import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import breakpoints from "assets/theme/base/breakpoints";
import Footer from "examples/Footer";
import ControllerCard from "examples/Cards/ControllerCard";

// Data
import dataTableData from "../topvisits/data/dataTableData";
import PageLayout from "examples/LayoutContainers/PageLayout";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import PlatformSettings from "../layouts/PlatformSettings/index";
import RankingsList from "../layouts/RankingsList";
import rankingsListData from "../layouts/RankingsList/data";

// icons
import { IoWallet } from "react-icons/io5";
import TriviaQuestionRewards from "../layouts/Notifications";
import { FaPowerOff } from "react-icons/fa";

// API Call
import { updateProperty } from "layouts/admin/api/property";
import { getMaintenanceModeProperty } from "api/property";

function Settings() {
  const { values } = breakpoints;
  const { transactionsData, revenueData } = rankingsListData;

  const [lights, setLights] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState({
    id: 0,
  });

  const handleSetLights = (e) => {
    setLights(!lights);
    updateProperty(maintenanceMode.id, !lights);
  };

  useEffect(() => {
    getMaintenanceModeProperty().then((response) => {
      setMaintenanceMode(response);
      setLights(response.value === "true");
    });
  }, []);

  console.log(lights);

  return (
    <>
      <PageLayout>
        <AdminNavbar />
        <Grid container justifyContent="center" mt={15} p={2}>
          <Grid item xs={9} lg={9}>
            <VuiBox mb={3} p={2}>
              <VuiTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
                color="white"
              >
                Settings
              </VuiTypography>
            </VuiBox>
            <Grid container>
              <Grid item xs={12} md={6} lg={6} xl={6} p={2}>
                <VuiBox>
                  <MiniStatisticsCard
                    title={{ text: "Today's Unique User Visits", fontWeight: "bold" }}
                    count="$53,000"
                    percentage={{ color: "success", text: "+55%" }}
                    icon={<IoWallet color="white" />}
                  />
                </VuiBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6} p={2}>
                <VuiBox mb={3}>
                  <MiniStatisticsCard
                    title={{ text: "Today's Total Visits", fontWeight: "bold" }}
                    count="653,000"
                    percentage={{ color: "success", text: "+255%" }}
                    icon={<IoWallet color="white" />}
                  />
                </VuiBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6} p={2}>
                <VuiBox mb={3}>
                  <PlatformSettings />
                </VuiBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6} p={2}>
                <VuiBox mb={3}>
                  <TriviaQuestionRewards />
                </VuiBox>
              </Grid>
              <Grid item xs={12} md={3} lg={3} xl={3} p={2}>
                <VuiBox mb={3}>
                  <ControllerCard
                    state={lights}
                    icon={<FaPowerOff color={lights ? "green" : "white"} size="52px" />}
                    title="Maintenance Mode"
                    onChange={handleSetLights}
                  />
                  {/* <DeleteAccount /> */}
                </VuiBox>
              </Grid>

              <Grid item xs={12} md={6} lg={6} xl={6} p={2}>
                <RankingsList
                  title="transactions"
                  date="23 - 30 March 2021"
                  rankings={transactionsData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" mb={3}>
          <Footer />
        </Grid>
      </PageLayout>
    </>
  );
}

export default Settings;
