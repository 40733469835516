// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";
// import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React example components
import AdminNavbarLink from "examples/Navbars/AdminNavbar/AdminNavbarLink";
import palette from "assets/theme/base/colors";
import tripleLinearGradient from "assets/theme/functions/tripleLinearGradient";

function AdminNavbarMobile({ open, close }) {
  const { width } = open && open.getBoundingClientRect();

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      sx={{
        marginTop: "8px",
      }}
      MenuListProps={{
        style: {
          width: `calc(${width}px - 4rem)`,
          backgroundImage: tripleLinearGradient(
            palette.gradients.cover.main,
            palette.gradients.cover.state,
            palette.gradients.cover.stateSecondary,
            palette.gradients.cover.deg
          ),
          backgroundColor: "unset !important",
          padding: "8px",
        },
      }}
    >
      <VuiBox px={0.5}>
        <AdminNavbarLink icon="home" name="Dashboard" route="/admin/dashboard" />
        <AdminNavbarLink icon="business" name="Building" route="/admin/building-table" />
        <AdminNavbarLink icon="leaderboard" name="Top Visit Lands" route="/admin/top-visits" />
        <AdminNavbarLink icon="account_circle" name="User Login" route="/admin/user-login" />
        <AdminNavbarLink icon="settings" name="Settings" route="/admin/settings" />
      </VuiBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
AdminNavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};

export default AdminNavbarMobile;
