import React, { useState, useEffect } from "react";
import ReactGlobe from "react-globe";
import { setGlobalState } from "context/globalstate";

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

import defaultMarkers from "./markers";

// @material-ui core components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

import WorldMap from "assets/images/solsphere/world-map.jpg";
import SolgemsCard from "./solgemscard";

import WalletConnectButton from "examples/Buttons/WalletConnect";
import WorldLandCards from "./landscard";

// Custom styles for DashboardNavbar
import { navbarMobileMenu } from "examples/Navbars/DashboardNavbar/styles";

// Vision UI Dashboard React context
import { useVisionUIController, setMiniSidenav } from "context";

function markerTooltipRenderer(marker) {
  return `LAND: ${marker.city}`;
}

const options = {
  markerTooltipRenderer,
  enableMarkerGlow: true,
  enableMarkerTooltip: true,
  markerRadiusScaleRange: [0.02, 0.02],
  markerType: "dot",
};

function World() {
  const randomMarkers = defaultMarkers.map((marker) => ({
    ...marker,
    value: Math.floor(Math.random() * 100),
  }));

  const [markers] = useState(randomMarkers);
  const [landInfo, setLandInfo] = useState({
    id: 0,
    image: "",
    city: "",
    description: "",
  });
  const [event, setEvent] = useState(null);
  const [details, setDetails] = useState(null);

  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav } = controller;

  const [showLandCard, setShowLandCard] = useState(false);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  function onClickMarker(marker, markerObject, event) {
    setEvent({
      type: "CLICK",
      marker,
      markerObjectID: markerObject.uuid,
      pointerEventPosition: { x: event.clientX, y: event.clientY },
    });
    setDetails(markerTooltipRenderer(marker));
    setLandInfo(marker);

    setGlobalState("isLandCardOpened", false);
    setShowLandCard(true);
  }

  function onDefocus(previousFocus) {
    setEvent({
      type: "DEFOCUS",
      previousFocus,
    });
    setDetails(null);
    setLandInfo(randomMarkers);

    setGlobalState("isLandCardOpened", false);
    setShowLandCard(false);
  }

  const handleOpen = (parameter) => (event) => {
    if (event.detail == 1) {
      setGlobalState("isLandCardOpened", true);
      setGlobalState("openLandCard", parameter);
    }
  };

  return (
    <>
      <VuiBox
        style={{
          position: "absolute",
          borderRadius: 20,
          fontSize: 20,
          top: 0,
          right: 30,
          padding: 20,
        }}
      >
        <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          <VuiBox color={"inherit"}>
            <IconButton
              size="small"
              color="inherit"
              sx={navbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon className={"text-white"}>{miniSidenav ? "menu_open" : "menu"}</Icon>
            </IconButton>
          </VuiBox>
          <WalletConnectButton />
        </Grid>
      </VuiBox>

      {showLandCard ? (
        <VuiBox
          style={{
            background: "#111546",
            position: "absolute",
            borderRadius: 20,
            marginRight: 10,
            marginTop: 10,
            fontSize: 20,
            top: 0,
            right: 0,
            width: 400,
            padding: 20,
          }}
        >
          <DefaultProjectCard
            image={landInfo.image}
            label={"Land continent #" + landInfo.id}
            title={landInfo.city}
            description={landInfo.description}
            handleClick={handleOpen(landInfo.path)}
            action={{
              type: "internal",
              color: "info",
              label: "view lands",
              route: "#",
            }}
          />
        </VuiBox>
      ) : null}

      <WorldLandCards />
      <ReactGlobe
        height="99.1vh"
        globeBackgroundTexture="https://unpkg.com/three-globe@2.23.5/example/img/night-sky.png"
        globeTexture={WorldMap}
        globeCloudsTexture="https://unpkg.com/three-globe@2.23.5/example/img/earth-topology.png"
        markers={markers}
        options={options}
        width="100vw"
        onClickMarker={onClickMarker}
        onDefocus={onDefocus}
      />
      <SolgemsCard />
    </>
  );
}
export default World;
