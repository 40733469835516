import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Modal from "@mui/material/Modal";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Setting pages components
import TableCell from "../TableCell";

// api calls
import { getTriviaRewardsProperty, updateProperty } from "layouts/admin/api/property";
import VuiInput from "components/VuiInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  border: "2px solid #ffffff",
  boxShadow: 24,
  p: 3,
};

function TriviaQuestionRewards() {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [newValue, setNewValue] = useState();
  const [selected, setSelected] = useState({
    name: "",
  });
  const toggleModal = () => setOpen(!open);
  const [sync, setSync] = useState(false);

  // Table Handling
  useEffect(() => {
    getTriviaRewardsProperty().then((response) => {
      setData(response);
    });
    // eslint-disable-next-line
  }, [sync]);

  const updatePropertyConfig = (e, row) => {
    e.preventDefault();
    setSelected(row);
    toggleModal();
  };

  const submitPropertyUpdate = (e) => {
    e.preventDefault();

    toggleModal();
    updateProperty(selected.id, newValue);
    setSync(!sync);
  };

  return (
    <Card id="notifications">
      <VuiBox lineHeight={1} mb="45px">
        <VuiBox>
          <VuiTypography variant="lg" color="white" fontWeight="bold">
            Trivia Questions Rewards Amount
          </VuiTypography>
        </VuiBox>
        <VuiTypography variant="button" color="text" fontWeight="regular">
          Choose amount of player's received per trivia difficulty.
        </VuiTypography>
      </VuiBox>
      <VuiBox>
        <VuiBox minWidth="auto" overflow={{ sm: "scroll", lg: "hidden" }}>
          <Table sx={{ minWidth: "36rem" }}>
            <VuiBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                  Activity
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  SMETA
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  Action
                </TableCell>
              </TableRow>
            </VuiBox>
            <TableBody>
              {data.map((row) => {
                return (
                  <TableRow>
                    <TableCell padding={[2, 1, 2, 0.5]}>
                      <VuiBox lineHeight={1.4}>
                        <VuiTypography
                          display="block"
                          variant="button"
                          color="white"
                          fontWeight="regular"
                        >
                          {row.label}
                        </VuiTypography>
                        <VuiTypography variant="caption" color="text" fontWeight="regular">
                          {row.name}
                        </VuiTypography>
                      </VuiBox>
                    </TableCell>

                    <TableCell align="center" padding={[2, 1, 2, 0.5]}>
                      <VuiInput disabled align="center" value={row.value} />
                    </TableCell>
                    <TableCell align="center" padding={[2, 1, 2, 0.5]}>
                      <VuiButton color="info" onClick={(e) => updatePropertyConfig(e, row)}>
                        Update
                      </VuiButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </VuiBox>
      </VuiBox>

      <Modal
        open={open}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <VuiBox>
            <VuiTypography id="modal-modal-title" variant="h6" component="h2" color="white">
              Update property
            </VuiTypography>
            <VuiTypography id="modal-modal-description" sx={{ mt: 2 }} color="white">
              New SMETA Reward for <b>{selected.name}</b> ?
              <VuiInput
                align="center"
                onChange={(e) => setNewValue(e.target.value)}
                defaultValue={selected.value}
                sx={{ mt: 2 }}
              />
              <VuiButton sx={{ mt: 2, mr: 1 }} mt={4} color="info" onClick={submitPropertyUpdate}>
                Update
              </VuiButton>
              <VuiButton
                sx={{ mt: 2 }}
                mt={4}
                variant="outlined"
                color="white"
                onClick={(e) => toggleModal()}
              >
                Close
              </VuiButton>
            </VuiTypography>
          </VuiBox>
        </Card>
      </Modal>
    </Card>
  );
}

export default TriviaQuestionRewards;
