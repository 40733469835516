import fetchClient from "api/axios";

const getSessionID = () => {
  return store.getState().auth.uuid;
};

export const getSolgemByMintAddress = async (mintAddress) => {
  const response = await fetchClient.get(`/solgem/find-by?mintAddress=${mintAddress}`);
  return response.status === 200 ? response.data[0] : [];
};

const getSolgemByIndex = async (index) => {
  const response = await fetchClient.get(`/solgem/find-by?solgemIndex=${index}`);
  return response.status === 200 ? response.data[0] : [];
};

export const getWalletLandIndexed = async () => {
  const response = await fetchClient.get(
    `/api/v1/block-chain/wallet-solgem-nft/` + localStorage.getItem("WALLET_ID")
  );
  return response.status === 200 ? response.data[0] : [];
};

export const getSolgemWalletMintAddressByIndex = async (index) => {
  const response = await fetchClient.get("/api/v1/solgem/find-by?solgemIndex=" + index);
  return response.status === 200 ? response.data[0] : [];
};
