import fetchClient from "api/axios";

import { nofitySuccess } from "components/Toasts";

export const getTriviaRewardsProperty = async () => {
  const response = await fetchClient.get(`/api/v1/properties/trivia`);

  return response.status === 200 ? response.data : [];
};

export const updateProperty = async (id, value) => {
  await fetchClient.patch(`/api/v1/properties/${id}?value=${value}`).then((response) => {
    nofitySuccess(`Property ${response.data.name} changed!`);
  });
};
